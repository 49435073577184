import { useParams, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactQuill from "react-quill";
import Swal from "sweetalert2";

import "./Desarrollo.scss";
import "react-quill/dist/quill.snow.css";
import { Popup } from "../../../Components/Popup/Popup";
import DragDrop from "../../../Components/DragDrop/DragDrop";
import { PDFVisualizer } from "../../../Components/PDFVisualizer/PDFVisualizer";
import { ContainerImage } from "../../../Components/ContainerImage/ContainerImage";
import LoadingSpinner from "../../../Components/LodingSpiner/CustomCircularProgress";
import CustomCircularProgress from "../../../Components/LodingSpiner/CustomCircularProgress";
import CreateInteractiveMap from "../../../Components/CreateInteractiveMap/CreateInteractiveMap";
import { IconSave, IconPlus, IconEditDoc, IconChevron } from "../../../svgs";
import {
    uploadToS3,
    updateDevelopment,
    getDevelopmentById,
    auctionsDevelopment,
    updateLogoDevelopment,
    updateBannerDevelopment,
    updateGalleryDevelopment,
} from "../../../Utils/endpoints";

const Desarrollo = () => {
    const params = useParams();
    const navigate = useNavigate();

    const [logoFile, setLogoFile] = useState([]);
    const [bannerFile, setBannerFile] = useState([]);
    const [development, setDevelopment] = useState();
    const [lotesEdited, setLotesEdited] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [documentFile, setDocumentFile] = useState([]);
    const [galleryImages, setGalleryImages] = useState([]);
    const [popupAddImage, setPopupAddImage] = useState(false);
    const [popupEditLogo, setPopupEditLogo] = useState(false);
    const [auctionActives, setAuctionActives] = useState(null);
    const [popupEditBanner, setPopupEditBanner] = useState(false);
    const [descriptionValue, setDescriptionValue] = useState(null);
    const [popupEditDocumentation, setPopupEditDocumentation] = useState(false);

    const [updatedGallery, setUpdatedGallery] = useState([]);

    const getDevelopment = async (id) => {
        getDevelopmentById({
            id: id,
        }).then((res) => {
            if (res.status === 200) {
                setDevelopment(res.data);
                setDescriptionValue(res.data?.description);
                setLogoFile(res.data?.imageDevelopment?.logo);
                setBannerFile(res.data?.imageDevelopment?.banner);
                setGalleryImages(res.data?.imageDevelopment?.gallery);
                setDocumentFile({ fileURL: res.data?.imageDevelopment?.documentation });
            }
        });
    };

    const getAuctionsDevelopment = async (developmentId) => {
        auctionsDevelopment(developmentId).then((response) => {
            if (response.data) {
                setAuctionActives(response.data);
            }
        });
    };

    useEffect(() => {
        getDevelopment(params?.id);
        getAuctionsDevelopment(params?.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    const handlePopupAddImage = () => {
        setPopupAddImage(true);
    };

    const handlePopupDocumentation = () => {
        setPopupEditDocumentation(true);
    };

    const handlePopupLogo = () => {
        setPopupEditLogo(true);
    };

    const handlePopupBanner = () => {
        setPopupEditBanner(true);
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        let result = await updateDevelopment({
            lots: lotesEdited,
            description: descriptionValue,
            documentation: documentFile.file,
            developmentId: development.id,
            imageDevelopmentId: development.imageDevelopment.id,
        });

        if (updatedGallery !== galleryImages && updatedGallery.length !== 0) {
            await updateGalleryDevelopment(updatedGallery, development.imageDevelopment.id);
        }

        if (logoFile !== development.imageDevelopment?.logo) {
            await updateLogoDevelopment(logoFile, development.imageDevelopment.id);
        }

        if (bannerFile !== development.imageDevelopment?.banner) {
            await updateBannerDevelopment(bannerFile, development.imageDevelopment.id);
        }

        if (result) {
            Swal.fire("Actualizado", "El desarrollo ha sido actualizado con éxito.", "success");
        } else {
            Swal.fire("Error", "Ha sucedido un error al intentar actualizar el desarrollo.", "error");
        }
        setIsLoading(false);
        navigate("/desarrollos");
    };

    if (!development)
        return (
            <div style={{ width: "100%", height: "90vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <LoadingSpinner />
            </div>
        );

    return (
        <>
            <section className='desarrollo'>
                <div className='section-header-logo'>
                    {/* <img src={development.imageDevelopment.logo} alt='logo' width={320} height={160} loading='lazy' decoding='async' /> */}
                    <img src={logoFile} alt='logo' width={320} height={150} loading='lazy' decoding='async' />

                    <span onClick={handlePopupLogo} className='change-logo-popup'>
                        <IconEditDoc width='20' height='20' stroke='#fcf4e6' />
                    </span>
                </div>

                <div className='section-header'>
                    <article className='navigate-page'>
                        <Link to={"/"}>Dashboard</Link>

                        <IconChevron />

                        <Link to={"/desarrollos"}>Desarrollos</Link>

                        <IconChevron />

                        <p>{development.name}</p>
                    </article>
                    <article className='actions-page'>
                        {isLoading ? (
                            <div
                                style={{ width: "109px", height: "45px", display: "flex", justifyContent: "center", alignItems: "center" }}
                            >
                                <LoadingSpinner size={20} color='#14213d' padding='5px' />
                            </div>
                        ) : (
                            <button className='main-btn' onClick={handleSubmit}>
                                <IconSave fill='#fcf4e6' width='15' height='15' />
                                Guardar
                            </button>
                        )}

                        <Link to={"/desarrollos/desarrollo/id/administrar-lote"} className='main-btn'>
                            <IconPlus />
                            Agregar Lote
                        </Link>
                    </article>
                </div>

                <div className='section-body-developments'>
                    <div className='input-label'>
                        <ReactQuill theme='snow' value={descriptionValue} onChange={setDescriptionValue} />
                    </div>

                    <div className='section-body-developments__banner'>
                        <h3>Banner</h3>

                        <div className='section-body-developments__banner__content'>
                            <img src={bannerFile} alt='banner' />

                            <span onClick={handlePopupBanner} className='change-logo-popup'>
                                <IconEditDoc width='20' height='20' stroke='#fcf4e6' />
                            </span>
                        </div>
                    </div>

                    <div className='section-body-developments__gallery'>
                        <h3>Galería</h3>

                        <div className='section-body-developments__gallery__content'>
                            <ContainerImage
                                handlePopupAddImage={handlePopupAddImage}
                                setUpdatedGallery={setUpdatedGallery}
                                galleryImages={galleryImages}
                            />
                        </div>
                    </div>

                    <article className='edit-lots-development'>
                        <h3 style={{ marginBottom: 10 }}>Loteo</h3>

                        <div className='leyendas-desarrollo'>
                            <div className='leyendas-desarrollo__disponibles'>
                                <div /> <p>Disponible</p>
                            </div>
                            <div className='leyendas-desarrollo__subastas_activas'>
                                <div /> <p>Subasta activa</p>
                            </div>
                            <div className='leyendas-desarrollo__vendidos'>
                                <div /> <p>Vendido</p>
                            </div>
                        </div>

                        {development ? (
                            <CreateInteractiveMap
                                imageFile={development.imageDevelopment.desarrollo_plane}
                                setLotesEdited={setLotesEdited}
                                auctionActives={auctionActives}
                                lots={development.lots}
                                id={development.id}
                                containerHeight='100%'
                            />
                        ) : null}
                    </article>

                    <article className='edit-pdf-documentation'>
                        <div className='edit-pdf-documentation__header'>
                            <h3>Documentación</h3>
                            <span onClick={handlePopupDocumentation}>
                                <p>Cambiar</p>
                                <IconEditDoc width='20' height='20' stroke='#fcf4e6' />
                            </span>
                        </div>

                        <PDFVisualizer pdf={documentFile?.fileURL} />
                    </article>
                </div>
            </section>

            <PopupDragDrop
                open={popupAddImage}
                development={development}
                setOpen={setPopupAddImage}
                galleryImages={galleryImages}
                setGalleryImages={setGalleryImages}
            />

            <PopupDocumentation
                documentFile={documentFile}
                open={popupEditDocumentation}
                setDocumentFile={setDocumentFile}
                setOpen={setPopupEditDocumentation}
            />

            <PopupDocumentation
                documentFile={documentFile}
                open={popupEditDocumentation}
                setDocumentFile={setDocumentFile}
                setOpen={setPopupEditDocumentation}
            />

            <PopupLogo logoFile={logoFile} setLogoFile={setLogoFile} open={popupEditLogo} setOpen={setPopupEditLogo} />
            <PopupBanner bannerFile={bannerFile} setBannerFile={setBannerFile} open={popupEditBanner} setOpen={setPopupEditBanner} />
        </>
    );
};

export default Desarrollo;

const PopupDragDrop = ({ open, setOpen, development, galleryImages, setGalleryImages }) => {
    const [galleryFiles, setGalleryFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const uploadNewImages = async () => {
        if (galleryFiles.length === 0) return;
        setIsLoading(true);

        const uploadPromises = [];
        const galleryFilesUrls = [];

        for (let file of galleryFiles) {
            const formData = new FormData();
            formData.append("file", file);
            uploadPromises.push(
                uploadToS3(formData).then((response) => {
                    if (response.status === 200) {
                        galleryFilesUrls.push(response.data.photoUrl);
                    }
                })
            );
        }

        await Promise.all(uploadPromises);

        let uploadedImages = [...galleryImages, ...galleryFilesUrls];
        setGalleryImages((prev) => [...prev, ...galleryFilesUrls]);

        updateGalleryDevelopment(uploadedImages, development.imageDevelopment.id).then(() => {
            setIsLoading(false);
        });

        setOpen(false);
    };

    useEffect(() => {
        return () => {
            setGalleryFiles([]);
        };
    }, [open]);

    return (
        <Popup open={open} setOpen={setOpen}>
            <div className='container-add-image-popup'>
                <DragDrop
                    files={galleryFiles}
                    setFiles={setGalleryFiles}
                    isMultiple={true}
                    text={"Arrastrá y soltá las imágenes"}
                    accept={"image/png, image/jpeg, image/webp"}
                    name='galeria'
                />
            </div>

            {isLoading ? (
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <CustomCircularProgress size={20} color='#14213d' padding='5px' />
                </div>
            ) : (
                <button className='container-add-image-button' onClick={uploadNewImages}>
                    Subir imagenes
                </button>
            )}
        </Popup>
    );
};

const PopupDocumentation = ({ open, setOpen, setDocumentFile }) => {
    const [file, setFile] = useState([]);

    useEffect(() => {
        return () => {
            setFile([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const handleSave = (e) => {
        e.preventDefault();

        if (file.length === 0) return null;
        const selectedFile = file[0];
        let fileURL = URL.createObjectURL(file[0]);

        setDocumentFile({ file: selectedFile, fileURL: fileURL });
        setOpen(false);
    };

    return (
        <Popup open={open} setOpen={setOpen}>
            <div className='container-add-documentation-popup'>
                <DragDrop
                    files={file}
                    setFiles={setFile}
                    text={"Arrastrá y soltá el documento"}
                    accept={"application/pdf"}
                    name='documentacion'
                />
            </div>

            <button className='container-add-image-button' onClick={handleSave}>
                Confirmar
            </button>
        </Popup>
    );
};

const PopupLogo = ({ open, setOpen, setLogoFile }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [file, setFile] = useState([]);

    useEffect(() => {
        return () => {
            setFile([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const uploadNewLogo = async () => {
        if (file.length === 0) return;
        setIsLoading(true);

        try {
            const formData = new FormData();
            formData.append("file", file[0]);

            await uploadToS3(formData).then((response) => {
                if (response.status === 200) {
                    setLogoFile(response.data.photoUrl);
                }
            });

            setIsLoading(false);
            setOpen(false);
        } catch (error) {
            console.error(error);
            setIsLoading(false);
        }
    };

    return (
        <Popup open={open} setOpen={setOpen}>
            <div className='container-add-documentation-popup'>
                <DragDrop files={file} setFiles={setFile} text={"Arrastrá y soltá el logo"} accept={"image/svg+xml"} name='logo' />
            </div>

            {isLoading ? (
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <CustomCircularProgress size={20} color='#14213d' padding='5px' />
                </div>
            ) : (
                <button className='container-add-image-button' onClick={uploadNewLogo}>
                    Subir imagenes
                </button>
            )}
        </Popup>
    );
};

const PopupBanner = ({ open, setOpen, setBannerFile }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [file, setFile] = useState([]);

    useEffect(() => {
        return () => {
            setFile([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const uploadNewBanner = async () => {
        if (file.length === 0) return;
        setIsLoading(true);

        try {
            const formData = new FormData();
            formData.append("file", file[0]);

            await uploadToS3(formData).then((response) => {
                if (response.status === 200) {
                    setBannerFile(response.data.photoUrl);
                }
            });

            setIsLoading(false);
            setOpen(false);
        } catch (error) {
            console.error(error);
            setIsLoading(false);
        }
    };

    return (
        <Popup open={open} setOpen={setOpen}>
            <div className='container-add-documentation-popup'>
                <DragDrop
                    files={file}
                    setFiles={setFile}
                    text={"Arrastrá y soltá el banner"}
                    accept={"image/png, image/jpeg, image/webp"}
                    name='banner'
                />
            </div>

            {isLoading ? (
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <CustomCircularProgress size={20} color='#14213d' padding='5px' />
                </div>
            ) : (
                <button className='container-add-image-button' onClick={uploadNewBanner}>
                    Subir imagen
                </button>
            )}
        </Popup>
    );
};
